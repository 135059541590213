html {
  margin: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

#root,
#root > div {
  height: 100%;
}

.root-portal {
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(141, 141, 141, 0.705);
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  font-size: 14px;
  background: #028090;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  /* min-width: 150px; */
  padding: 1rem 1rem;
  border: 1px solid lightgray;
  margin-bottom: 2px;
  /* min-height: 40px; */
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 1rem 1rem;
  margin: 0;
  text-decoration: none;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:hover {
}

button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

button:active {
}
